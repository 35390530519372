import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
 <h1>NOT FOUND</h1>
  <p>Hello Time traveller, you've hit a route that doesn't exist yet.  Try again in a few years.</p>
  </Layout>
)

export default NotFoundPage
